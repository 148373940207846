<template>
  <div
    class="hello"
    @scroll="scrollHandle"
  >
    <slot></slot>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "loadMore",
  data() {
    return {};
  },
  methods: {
    scrollHandle: _.debounce(function (e) {
      if (
        e.target.scrollTop + e.target.clientHeight >
        e.target.scrollHeight-20
      ) {
        this.$emit("loading");
      }
    }, 100),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  height: 300px;
  width: 100%;
  overflow: auto;
}
</style>
