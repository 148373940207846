<template>
  <div class="hello">
    <div @click="sortClick('0')" :class="active==0?'active_class':''"><i class="el-icon-caret-top"></i></div>
    <div class="bottom" @click="sortClick('1')" :class="active==1?'active_class':''"><i class="el-icon-caret-bottom" ></i></div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data(){return{
      active:0
  }},
  methods:{
      sortClick(msg){
          if(this.active == msg){
              return false
          }
          this.active = msg
          this.$emit('sort',msg)
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
i{
    font-size: 25px;
}
.bottom{
    margin-top: -10px;
}
.active_class{
color: #409eff;
}
</style>
