<template>
  <div class="order">
    <div class="filter">
      <div class="flex_center_col">
        <Page :current="problemListPar.page" :total="taskListTotal" simple @on-change="pageChange" style="margin-left: 10px;margin-right:20px" />
        <div>
          <div style="margin-right:20px">
            <el-radio v-model="problemListPar.sort.create_time" label="ASC">日期升序</el-radio>
          </div>
          <div>
            <el-radio v-model="problemListPar.sort.create_time" label="DESC">日期降序</el-radio>
          </div>
        </div>
        <el-date-picker v-model="date_stamp" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp" style="width:200px;margin-right:20px">
        </el-date-picker>
        <el-select v-model="value1" placeholder="项目数据" style="width:150px;margin-right:20px" @change="handleChange">
          <el-option v-for="item in departmentList" :key="item.index" :label="item.name" :value="item.index">
          </el-option>
        </el-select>
      </div>

      <el-select v-model="problemListPar.status" placeholder="状态选择" style="width:150px;margin-right:20px">
        <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input placeholder="请输入关键字" v-model.lazy="keyword" style="width:200px;margin-right:20px">
        <el-button slot="append" icon="el-icon-search" @click="detailKeyword"></el-button>
      </el-input>
      <el-button type="primary" style="position: absolute;
  right: 0;" @click="toNewOrder">发布工单</el-button>
    </div>
    <div class="list">
      <div class="left">
        <div v-if="problemList.length === 0" class="flex_center_col_100">
          暂无列表
        </div>
        <div class="item" v-for="item in problemList" :key="item.id" @click="getInfo(item.id)" :class="item.id == problemDetail.id ? 'active_color' : ''">
          <div class="info">
            <el-avatar icon="el-icon-user-solid" :src="item.create_user_avatar" v-if="item.create_user_avatar" @error="errorHandler" size="small" style="margin-right:16px"><img :src="circleUrl" />
            </el-avatar>
            <el-avatar icon="el-icon-user-solid" :src="circleUrl" v-else size="small" style="margin-right:16px"></el-avatar>
            <div class="right">
              <div class="time">
                <div class="name">{{ item.create_user_name }}</div>
                <div class="creat_time">
                  {{
                    (item.create_time * 1000) | dateformat("YYYY-MM-DD HH:mm")
                  }}
                </div>
                <el-button type="primary" round v-if="item.status == 1" size="mini">已处理</el-button>
                <el-button type="danger" round v-else size="mini">处理中</el-button>
              </div>
              <div class="name">{{ item.inspection_name }}</div>
              <div class="location">{{ item.site_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="top" v-if="Object.keys(problemDetail).length > 0">
          <div class="info">
            <div class="item">
              <div class="title">工单编号</div>
              {{ problemDetail.number || "未定义" }}
            </div>
            <div class="item">
              <div class="title">创建时间</div>
              {{
                (problemDetail.create_time * 1000)
                  | dateformat("YYYY-MM-DD HH:mm")
              }}
            </div>
            <div class="item">
              <div class="title">创建人</div>
              {{ problemDetail.create_user_name }}
            </div>
            <div class="item">
              <div class="title">负责人</div>
              <div>
                <span v-for="item in problemDetail.trace_users" :key="item.id">{{
                item.name
              }}</span></div>
              <div v-if="problemDetail.trace_users.length===0">未指定</div>
            </div>
            <div class="item">
              <div class="title">负责部门</div>
              {{ problemDetail.trace_department_name }}
            </div>
            <div class="item">
              <div class="title">地点</div>
              {{ problemDetail.site_name }}
            </div>
            <div class="item">
              <div class="title">巡检项目</div>
              {{ problemDetail.inspection_name }}
            </div>
            <div class="item">
              <div class="title">状态</div>
              {{ problemDetail.status === 0 ? "处理中" : "已处理" }}
            </div>
          </div>
          <div class="remark" style="overflow: auto">
            <div class="title">
              <i class="el-icon-edit-outline"></i>
              备注
            </div>
            <div class="content">
              {{ problemDetail.inspection_logs[0].remark || "未填写" }}
            </div>
            <div class="imgBox">
              <el-image style="width: 90px; height: 90px;margin-right:8px" :src="item.url" :preview-src-list="remarkList" v-if="item.type == 1" v-for="item in problemDetail.files" :key="item.url">
              </el-image>

              <span v-for="media in problemDetail.files" @click="clickVideo(media.url)" style="
                            position: relative;
                            height: 90px;
                            width: 90px;
                            margin-right:8px
                          " v-if="media.type === 2">
                        <i class="el-icon-video-play"></i>
                        <video :src="media.url" @click="clickVideoSlef" style="height:90px;width:90px"></video>
                      </span>
              <div v-for="media in problemDetail.files" :key="media.url" v-if="media.type === 3">
                <audio :src="media.url" controls="controls"></audio>
              </div>

            </div>
          </div>
        </div>
        <div class="top flex_center_col_100" v-else>
          请选择工单
        </div>
        <load-more class="bottom" @loading="loadmore" v-if="this.oprationList.length > 0">
          <div class="title">
            处理情况
          </div>
          <div>
            <el-timeline>
              <el-timeline-item color="#0486FE" v-for="(item, index) in oprationList" :key="item.id">
                <div class="item">
                  <el-avatar icon="el-icon-user-solid" :src="item.create_user_avatar" @error="errorHandler" v-if="item.create_user_avatar" size="small" style="margin-right: 16px">
                    <img :src="circleUrl" />
                  </el-avatar>
                  <el-avatar icon="el-icon-user-solid" :src="circleUrl" v-else size="small" style="margin-right: 16px"></el-avatar>
                  <div class="right">
                    <div class="title">
                      <div class="name">
                        {{ item.create_user_name || "系统通知" }}
                      </div>
                      <div class="time">
                        {{
                          (item.create_time * 1000)
                            | dateformat("YYYY-MM-DD HH:mm")
                        }}
                      </div>
                    </div>
                    <div class="content">{{ item.remark }}</div>
                    <div class="imgBox">
                      <el-image style="width: 90px; height: 90px;margin-right:8px" :src="img.url" v-if="img.type == 1" :preview-src-list="detailImgList" v-for="img in item.files" :key="img.url">
                      </el-image>
                      <span v-for="media in item.files" @click="clickVideo(media.url)" style="
                            position: relative;
                            height: 90px;
                            width: 90px;
                            margin-right:8px
                          " v-if="media.type === 2">
                        <i class="el-icon-video-play"></i>
                        <video :src="media.url" @click="clickVideoSlef" style="height:90px;width:90px"></video>
                      </span>
                      <div v-for="media in item.files" :key="media.url" v-if="media.type === 3">
                        <audio :src="media.url" controls="controls"></audio>
                      </div>
                    </div>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </load-more>
        <div v-else class="bottom flex_center_col_100">
          {{ !this.oprationPar.id ? "请选择工单" : "暂无操作记录" }}
        </div>
      </div>
    </div>
    <div class="popContainer" v-show="videoVisible" @click="closeVideoPage">
      <i class="el-icon-circle-close" @click="closeVideoPage"></i>
      <video class="video-js" controls preload="auto" data-setup="{}" ref="myVideo" style="width: 70vw; height: 80vh" :src="videoSrc"></video>
    </div>
  </div>
</template>

<script>
import sort from '../../components/sort';
import { organization, task, problem } from '../../utils/api';
import loadMore from '../../components/loadMore';
import qs from 'qs';
export default {
  name:"order",
  data() {
    return {
      radio: '',
      value1: 0,
      keyword: '',
      date_stamp: '',
      circleUrl:
        'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      problemListPar: {
        status: null,
        site_id: '',
        scope: 2,
        user_id: '',
        department_id: '',
        date_stamp: '',
        keyword: '',
        sort: { create_time: 'ASC' },
        // sort:"ASC",
        page: 1,
        page_size: 10
      },
      taskListTotal: 0,
      departmentList: [],
      problemList: [],
      statusArr: [
        {
          label: '全部',
          value: null
        },
        {
          label: '正常',
          value: '1'
        },
        {
          label: '异常',
          value: '0'
        }
      ],
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      remarkList: [],
      problemDetail: {},
      isLoading: true,
      oprationPar: {
        id: '',
        page: 0,
        page_size: 10
      },
      oprationList: [],
      detailImgList: [],
      videoSrc: '',
      videoVisible: false
    };
  },
  created() {
    this.getDepartmenList();
    this.getProblemList();
  },
  computed: {},
  components: { 'load-more': loadMore },
  methods: {
    async getProblemList() {
      const res = await this.$http.post(
        problem.listProblem,
        this.problemListPar
      );
      this.problemList = res.data;
      this.taskListTotal = res.total;
    },
    handleChange(value) {
      Object.assign(this.problemListPar, this.departmentList[value].data);
    },
    pageChange(e) {
      this.problemListPar.page = e;
      this.getProblemList();
    },
    async getDepartmenList() {
      const res = await this.$http.get(organization.listDepartment);
      res.data.unshift({
        name: '我的数据',
        data: {
          user_id: this.$store.state.user_info.id,
          scope: 0,
          department_id: null
        }
      });
      res.data.unshift({
        name: '全部数据',
        data: { id: null, department_id: null, scope: 2 }
      });
      res.data.forEach((item, index) => {
        //  item.value={index:index+2,id:item.id,scope:2}
        item.index = index;
        if (index > 1) {
          item.data = { department_id: item.id, scope: 1, id: null };
        }
      });
      this.departmentList = res.data;
    },
    detailKeyword() {
      if (this.problemListPar.keyword === this.keyword) {
        return false;
      }
      this.problemListPar.keyword = this.keyword;
    },
    async getInfo(id) {
      if (id === this.problemDetail.id) {
        return false;
      }
      const res = await this.$http.get(problem.problemInfo + '?id=' + id);
      this.problemDetail = res.data;
      this.remarkList = [];
      res.data.files.forEach(item => {
        if (item.type == 1) {
          this.remarkList.push(item.url);
        }
      });
      this.oprationPar.id = id;
      this.oprationPar.page = 0;
      this.isLoading = true;
      this.oprationList = [];
      this.detailImgList = [];
      this.getOperationList();
    },
    loadmore() {
      this.getOperationList();
    },
    async getOperationList() {
      if (!this.isLoading) {
        return false;
      }
      this.oprationPar.page++;
      const res = await this.$http.get(
        problem.listOperation + '?' + qs.stringify(this.oprationPar)
      );
      res.data.forEach(item => {
        item.files.forEach(cItem => {
          if (cItem.type == 1) {
            this.detailImgList.push(cItem.url);
          }
        });
      });
      this.oprationList.push(...res.data);
      if (res.total == this.oprationList.length) {
        this.isLoading = false;
      }
    },
    clickVideoSlef() {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
    },
    clickVideo(url) {
      window.event ? (window.event.returnValue = false) : e.preventDefault();
      this.$refs.myVideo.src = url;
      this.$refs.myVideo.play();
      this.videoVisible = true;
    },
    closeVideoPage() {
      this.$refs.myVideo.pause();
      this.videoVisible = false;
    },
    toNewOrder() {
      this.$router.push('newOrder');
    },
    errorHandler() {
      return true;
    }
  },
  computed: {
    problemListPa() {
      return JSON.parse(JSON.stringify(this.problemListPar));
    }
  },
  watch: {
    problemListPa: {
      handler: function(newValue, oldValue) {
        if (newValue.page == oldValue.page) {
          this.problemListPar.keyword = this.keyword;
          this.problemListPar.page = 1;
          this.getProblemList();
        } else {
        }
      },
      deep: true
    },
    date_stamp(value) {
      this.problemListPar.date_stamp = value ? value / 1000 : null;
    }
  }
};
</script>
<style lang="scss" scoped>
.order {
  width: 1200px;
  //   background-color: aqua;
  .filter {
    width: 1193px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .list {
    margin-top: 8px;
    display: flex;
  }
  .left {
    width: 395px;
    height: 667px;
    background: #ffffff;
    border-radius: 4px;
    margin-right: 8px;
    overflow: auto;
    .item {
      padding: 16px;
      // margin: 0 16px;
      border-bottom: 1px solid #f4f4f4;
      .info {
        display: flex;
        .right {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 27px;
          width: 303px;
          .time {
            display: flex;
            .name {
              @include ellipsis;
              width: 85px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              margin-right: 20px;
            }
            .creat_time {
              width: 189px;
              height: 14px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 27px;
              margin-right: -40px;
            }
          }
        }
      }
    }
  }
  .right {
    width: 790px;
    .top {
      width: 790px;
      height: 259px;
      background: #ffffff;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 16px;
      display: flex;
      .info {
        box-sizing: border-box;
        width: 307px;
        height: 227px;
        padding: 16px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-right: 10px;
        .item {
          display: flex;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          .title {
            width: 90px;
          }
        }
      }
      .remark {
        width: 440px;
        height: 227px;
        background: #ffffff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 16px;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          line-height: 23px;
          .el-icon-edit-outline {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 700;
            color: #333333;
            line-height: 23px;
          }
        }
        .content {
          width: 393px;
          //height: 42px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
        }
        .imgBox {
          //margin-top: 8px;
        }
      }
    }
    .bottom {
      width: 790px;
      height: 398px;
      background: #ffffff;
      border-radius: 4px;
      margin-top: 10px;
      padding: 16px;
      overflow: auto;
      .title {
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #333333;
        line-height: 20px;
        margin-bottom: 20px;
      }
      .item {
        display: flex;
        .right {
          flex: 1;
          .title {
            display: flex;
            justify-content: space-between;
            line-height: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .name,
            .time {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .content {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 28px;
          }
          .imgBox {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .el-icon-video-play {
    position: absolute;
    top: -330%;
    left: 27%;
    font-size: 50px;
    color: #000000;
  }
}
div.popContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
</style>
